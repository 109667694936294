
import {
  modalController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonButtons,
  IonImg,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { SingleIngredient, IngredientSeason } from '@/types/ingredientTypes';
import axios from 'axios';
import { calculateKlimaPercent } from '@/utils/methods/scoreMethods';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'IngredientModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonImg,
  },
  props: {
    ingredient: {
      type: Object as () => SingleIngredient,
      required: true,
    },
    klimaRanking: {
      type: String,
    },
  },
  data() {
    return {
      ingredientSeason: null as IngredientSeason | null,
      tblVars,
    };
  },
  computed: {
    klimaPercent(): number | null {
      if (!this.ingredient) return null;
      const co2 = this.ingredient.co2_16fu_ohne_flug;
      if (!co2 || co2 === 0) return null;
      return calculateKlimaPercent(co2);
    },
  },
  mounted() {
    this.checkSeasonality(this.ingredient.id);
  },
  methods: {
    async dismissCurrentModal() {
      const modal = await modalController.dismiss();
      return modal;
    },
    checkSeasonality(ingredientId: string | number) {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'bigSearch',
          limit: `0,10`,
          orderby: 'ingredient_id',
          searchTable: 'ingredients_season',
          searchColumn: 'ingredient_id',
          sort: 'ASC',
          term: ingredientId,
        })
        .then(response => {
          if (typeof response.data === 'object' && response.data.length > 0) {
            response.data.forEach((ingredientResponse: IngredientSeason) => {
              if (ingredientResponse.ingredient_id === this.ingredient.id) {
                this.ingredientSeason = ingredientResponse;
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    // async openExplanationModal() {
    //   const modal = await modalController.create({
    //     component: ScoreExplanationModal,
    //     cssClass: 'explanation-modal',
    //     swipeToClose: true,
    //   });
    //   return modal.present();
    // },
  },
});
