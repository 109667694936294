
import { defineComponent } from 'vue';
import {
  modalController,
  alertController,
  IonPage,
  IonContent,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonImg,
  IonChip,
  IonLabel,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
} from '@ionic/vue';
import axios from 'axios';
import store from '@/store';
import { openInAppBrowser } from '@/utils/methods/capacitorMethods';
import { SingleRecipe } from '@/types/recipeTypes';
import { SingleIngredient, RecipeIngredient } from '@/types/ingredientTypes';
import { SingleRecipePartner } from '@/types/TaxonomyTypes';
import IngredientModal from '@/components/modals/IngredientModal.vue';
import router from '@/router';
import { getCookie } from '@/utils/methods/cookieMethods';
import { validateUser, setUserInfo } from '@/utils/methods/userMethods';
import { openToastOptions } from '@/utils/ionCustomHelpers';
import { ToastColor } from '@/types/ionicTypes';
import {
  getKlimaScoreRanking,
  calculateKlimaPercent,
  calculateWaterPercent,
  calculateVitaPercent,
} from '@/utils/methods/scoreMethods';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonImg,
    IonChip,
    IonLabel,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
  },
  data() {
    return {
      currentSlug: this.$route.params.slug,
      recipe: {} as SingleRecipe,
      ingredientInfoList: [] as Array<SingleIngredient>,
      recipePartnerInfo: {} as SingleRecipePartner,
      tblVars,
    };
  },
  mounted() {
    this.fetchRecipe(this.currentSlug);
    // this.fetchRecipeBySlug(this.currentSlug);
  },
  computed: {
    userLoggedIn: () => {
      return store.getters.getLoginState;
    },
    ingredientList(): Array<RecipeIngredient> {
      if (this.recipe.ingredients) {
        const ingredientObject = JSON.parse(this.recipe.ingredients);
        return Object.values(ingredientObject);
      } else {
        return [];
      }
    },
    userFavoriteRecipeIds: () => {
      return store.getters.getUserFavoriteRecipes;
    },
    klimaPercent(): number | null {
      if (!this.recipe || this.recipe.co2_emission === 0) return null;
      return calculateKlimaPercent(this.recipe.co2_emission);
    },
    waterPercent(): number | null {
      if (!this.recipe || parseInt(this.recipe.water_balance) < 1) return null;
      return calculateWaterPercent(parseInt(this.recipe.water_balance));
    },
    vitaPercent(): number | null {
      if (!this.recipe || this.recipe.vita_score < 1) return null;
      return calculateVitaPercent(this.recipe.vita_score);
    },
  },
  watch: {
    ingredientList(ingredientArray: Array<RecipeIngredient>) {
      const ingredientIdArray = ingredientArray.map(async (ingredient: RecipeIngredient) => {
        const ingredientInfoList = await axios
          .post(`${tblVars.baseApiUrl}/index.php`, {
            action: 'getIngredientInfo',
            ingredientId: ingredient.id,
          })
          .then(response => {
            return response.data;
          })
          .catch(error => {
            console.error(error);
          });
        return ingredientInfoList;
      });
      Promise.all(ingredientIdArray)
        .then(results => {
          const completeIngredientList = results.map((ingredient, index) => {
            // temporarily eased the following operator != because old ingredient id was a string, new one is a number
            if (ingredientArray[index].id != ingredient.id) throw 'ingredient lists could not be matched';

            return {
              ...ingredientArray[index],
              ...ingredient,
            };
          });
          this.ingredientInfoList = completeIngredientList;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  methods: {
    // fetchRecipeBySlug(slug: string | string[]) {
    //   axios.post(`${tblVars.baseApiUrl}/index.php`, {
    //     action: 'getBySlug',
    //     slug: slug,
    //     table: 'recipes'
    //   })
    //   .then( response => {
    //     console.log(response);
    //   })
    //   .catch( error => {
    //     console.error(error);
    //   })
    // },
    openInAppBrowser,
    getKlimaScoreRanking,
    fetchRecipe(slug: string | string[]) {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'getBySlug',
          slug: slug,
          table: 'recipes',
        })
        .then(response => {
          if (response.data && typeof response.data !== 'string') {
            this.recipe = response.data;
            if (response.data.partner) {
              this.getRecipePartner(response.data.partner);
            }
          } else if (typeof slug === 'string' && !isNaN(parseInt(slug))) {
            // adding support for recipe id as slugs
            axios
              .post('https://app.eatable.ch/index.php', {
                action: 'getSingleRecipe',
                recipeId: slug,
              })
              .then(response => {
                if (response.data && typeof response.data !== 'string') {
                  this.recipe = response.data;
                  if (response.data.partner) {
                    this.getRecipePartner(response.data.partner);
                  }
                } else {
                  this.$router.push('/404?source=recipe'); // no recipe found – redirect to 404 page
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async openIngredientModal(ingredientObject: SingleIngredient) {
      const modal = await modalController.create({
        component: IngredientModal,
        cssClass: 'ingredient-modal',
        componentProps: {
          ingredient: ingredientObject,
          klimaRanking: ingredientObject.co2_16fu_ohne_flug
            ? this.getKlimaScoreRanking(ingredientObject.co2_16fu_ohne_flug)
            : '',
        },
      });
      return modal.present();
    },
    getRecipePartner(id: string) {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'bigSearch',
          limit: `0,1`,
          orderby: 'title',
          searchTable: 'partners',
          searchColumn: 'id',
          sort: 'ASC',
          term: id,
        })
        .then(response => {
          this.recipePartnerInfo = response.data[0];
        })
        .catch(error => {
          console.error(error);
        });
    },
    favoriteRecipeToggle(event: any) {
      event.stopPropagation();
      if (!this.userLoggedIn) {
        this.userLoginPrompt();
      }
      const sessionToken = getCookie('sessionToken');
      if (!sessionToken) return;

      validateUser(sessionToken).then(userId => {
        let ajaxAction;
        let toastTitle = 'Zur Sammlung hinzugefügt';
        let toastColor: ToastColor = 'success';
        if (this.userFavoriteRecipeIds && JSON.parse(this.userFavoriteRecipeIds).includes(this.recipe.id)) {
          ajaxAction = 'removeUserFavoriteRecipe';
          toastTitle = 'Aus deiner Sammlung entfernt';
          toastColor = 'tertiary';
        } else {
          ajaxAction = 'addUserFavoriteRecipe';
        }
        axios
          .post(`${tblVars.baseApiUrl}/index.php`, {
            headers: {
              authorization: sessionToken,
            },
            action: ajaxAction,
            userId: userId,
            favoriteRecipe: this.recipe.id,
          })
          .then(() => {
            setUserInfo(sessionToken, userId);
            openToastOptions(toastTitle, toastColor, 2000, 'top');
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    async userLoginPrompt() {
      const alert = await alertController.create({
        header: 'Es freut uns, dass dir dieses Rezept gefällt!',
        message: 'Melde dich an, um es in deiner Sammlung zu speichern...',
        buttons: [
          {
            text: 'Konto erstellen',
            role: 'register',
            handler: () => router.push('/profil/registrierung'),
          },
          {
            text: 'zum Login',
            role: 'login',
            handler: () => router.push('/profil/login'),
          },
          {
            text: 'abbrechen',
            role: 'cancel',
          },
        ],
      });
      return alert.present();
    },
  },
});
